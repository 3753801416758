import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import EntertainmentVending from './pages/EntertainmentVending';
import VendingService from './pages/VendingService';
import AboutUs from './pages/AboutUs';
import Rentals from './pages/Rentals';
import Contact from './pages/Contact';

function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/entertainment-vending" element={<EntertainmentVending />} />
        <Route path="/vending-service" element={<VendingService />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/rentals" element={<Rentals />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;