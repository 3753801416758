import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = ({ title, description, url, image, keywords, pathToPage }) => (
    <Helmet>
        <link rel="canonical" href={`https://www.federalvendingservice.com/${pathToPage}`} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {keywords && <meta name="keywords" content={keywords.join(', ')} />}
    </Helmet>
);

export default HelmetComponent;