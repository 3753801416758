import React from 'react';
import { Helmet } from 'react-helmet';

const CommonMeta = () => (
    <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <meta name="msapplication-TileColor" content="transparent" />
        <meta name="twitter:image" content="https://www.federalvendingservice.com/cards/twitterFederalVendingService.jpg" />
        <meta property="og:image" content="https://www.federalvendingservice.com/cards/openGraphFederalVendingService.jpg" />
        {/* Google tag (gtag.js)  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-ZYGKDFPWBR"></script>
        <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-ZYGKDFPWBR');
            `}
        </script>
    </Helmet>
);

export default CommonMeta;